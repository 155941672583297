import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from "react-toastify"
import shop from "store/shop"

const MOCK_REACT_APP_API_URL = "https://ac02a420-e3d0-4a54-b40d-63ed957400d5.mock.pstmn.io"
export default class HashCampaignStore {
  campaigns = [];
  loading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  // Fetch all campaigns
  async fetchCampaigns() {
    this.loading = true;
    this.error = null;
    
    try {
      const response = await shop.fetch('/v1/customer/hash/campaign');
      const data = await response.json();
      
      runInAction(() => {
        this.campaigns = data;
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
    }
  }

  // Create new campaign
  async createCampaign(campaignData) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });
      
      const newCampaign = await response.json();
      
      runInAction(() => {
        this.campaigns.push(newCampaign);
        this.loading = false;
      });
      toast.success("Campaign created successfully!")
      return newCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
        toast.error("Error creating campaign. Please try again.")
      });
      throw error;
    }
  }

  // Update campaign
  async updateCampaign(campaignId, updates) {
    this.loading = true;
    this.error = null;

    try {
      const response = await shop.fetch(`/v1/customer/hash/campaign`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updates),
      });
      
      const updatedCampaign = await response.json();

      
      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          this.campaigns[index] = updatedCampaign;
        }
        this.loading = false;
      });
      
      return updatedCampaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }

  // Delete campaign
  async deleteCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    try {
      await shop.fetch(`/api/campaigns/${campaignId}`, {
        method: 'DELETE',
      });
      
      runInAction(() => {
        this.campaigns = this.campaigns.filter(
          c => c.marketingCampaignId !== campaignId
        );
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }

  // Get single campaign
  async fetchCampaign(campaignId) {
    this.loading = true;
    this.error = null;

    try {
      const response = await fetch(`/api/campaigns/${campaignId}`);
      const campaign = await response.json();
      
      runInAction(() => {
        const index = this.campaigns.findIndex(c => c.marketingCampaignId === campaignId);
        if (index !== -1) {
          this.campaigns[index] = campaign;
        } else {
          this.campaigns.push(campaign);
        }
        this.loading = false;
      });
      
      return campaign;
    } catch (error) {
      runInAction(() => {
        this.error = error.message;
        this.loading = false;
      });
      throw error;
    }
  }
}